import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
    faCloudArrowUp, faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { useNeoForm } from '@/composables/neoform';
import { cn } from '@/lib/utils';
import mime from 'mime-types';
import { v4 as uuid } from 'uuid';
import { postUploadFile } from '@/composables/api';
import { toast } from 'react-toastify';
import { processFile } from '@/composables/utils';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { FileItem } from '@/components/neoform/helper/FileItem';
import { useFieldArray } from 'react-hook-form';
import { FileBundle } from '@/components/neoform/helper/FileBundle';
import { ReactSortable } from 'react-sortablejs';
import { DateTime } from 'luxon';
import { getFieldError } from '@/composables/validation';
import { Button } from '@/components/ui/button';
import ObjectId from 'bson-objectid';
import _ from 'lodash';
import { useUserStore } from '@/store/user';

interface InputDropContainerProps {
    accept?: Record<string, string[]>;
    dropMessage?: TranslationObject;
    children?: React.ReactNode;
    maxFiles?: number;
    fileName?: string;
    bundled?: boolean;
    amend?: boolean;
}

export interface UploadedFile {
    _id: string;
    name: string;
    filename: string;
    content_type: string;
    last_modified: string;
    url?: string;
    token?: string;
    include: boolean;
    amend: boolean;
}

export interface UploadedFileBundle {
    id: string;
    name: string;
    fileList: string[];
    include: boolean;
    amend: boolean;
}

const ACCEPT_DEFAULT = {
    'image/*': ['.jpg', '.jpeg', '.png'],
    'application/pdf': ['.pdf']
};

const MESSAGE_DEFAULT = {
    en: '<b>Click to upload</b> or drag and drop files',
    fr: '<b>Cliquez pour téléverser</b> ou faites glisser et déposez des fichiers'
};

const BUNDLES_KEY = 'bundleList';
const FILES_KEY = 'fileList';

export function InputDropContainer(props: NeoFormComponentProps & InputDropContainerProps) {
    const { t, to } = useTranslation('neoform.drop-container');
    const { user } = useUserStore(state => ({ user: state.user }));
    const { form, hookForm, id, getChildFieldName, getValidationRules } = useNeoForm();
    const filesId = getChildFieldName(FILES_KEY);
    const filesRootId = getChildFieldName(FILES_KEY, 'root');
    const bundlesId = getChildFieldName(BUNDLES_KEY);
    const validate = getValidationRules(props);
    const {
        remove: removeFile,
        append: appendFiles,
        replace: replaceFiles,
        update: updateFile
    } = useFieldArray({ name: filesId, rules: { validate } });
    const {
        remove: removeBundle,
        append: appendBundles
    } = useFieldArray({ name: bundlesId });
    useEffect(() => {
        return () => {
            hookForm.unregister([filesId, bundlesId]);
        };
    }, []);
    const error = getFieldError(filesRootId, hookForm.formState);
    const files = form?.watch<UploadedFile[]>(filesId) ?? [];
    const bundles = form?.watch<UploadedFileBundle[]>(bundlesId) ?? [];

    const accept = props.accept ?? ACCEPT_DEFAULT;
    const message = props.dropMessage ?? MESSAGE_DEFAULT;
    const [bundleCounter, setBundleCounter] = useState(0);
    const { getRootProps, getInputProps } = useDropzone({
        accept,
        multiple: !props.maxFiles ? true : props.maxFiles > 1,
        maxFiles: props.maxFiles,
        onDrop: (acceptedFiles, fileRejections) => {
            if (props.maxFiles) {
                const files = form?.get<UploadedFile[]>(filesId) ?? [];
                if (files.length + acceptedFiles.length > props.maxFiles) {
                    toast(
                        to({
                            en: `Maximum ${props.maxFiles} file(s) allowed`,
                            fr: `Maximum ${props.maxFiles} fichier(s) autorisé(s)`
                        }),
                        { type: 'error' }
                    );
                    return;
                }
            }
            const newFiles: UploadedFile[] = acceptedFiles.map(f => ({
                _id: ObjectId().toHexString(),
                name: props.fileName ?? f.name,
                filename: f.name,
                content_type: mime.lookup(f.name) || 'application/octet-stream',
                last_modified: DateTime.fromMillis(f.lastModified).toISO() ?? '',
                include: true,
                amend: false
            }));
            if (props.bundled && newFiles.length > 0) {
                addBundle(newFiles.map(f => f._id));
            }
            appendFiles(newFiles);
            acceptedFiles.forEach((f, i) => {
                const title = _.get(form?.meta ?? {}, `${id}.meta.title`) ?? {};
                processFile(f)
                    .then(() => postUploadFile(f, {
                        _id: newFiles[i]._id,
                        last_modified: f.lastModified,
                        title_fr: title.fr,
                        title_en: title.en,
                        folder_id: form?.info?.ticket_id ?? '',
                        case_id: form?.info?.case_id,
                        task_id: form?.info?.task_id ?? '',
                        form_id: form?.info?.form_id ?? '',
                        form_name: form?.info?.name ?? '',
                        target: form?.info?.target ?? '',
                        email: user?.email,
                        input_name: id,
                        category: 'req_form'
                    }))
                    .then((res) => {
                        const files = form?.get<UploadedFile[]>(filesId) ?? [];
                        const file = files.find(f => f._id === newFiles[i]._id);
                        if (file) {
                            file.url = res.data.url;
                        }
                        replaceFiles([...files]);
                    })
                    .catch(() => {
                        toast(
                            to({
                                en: `Failed to upload file: '${f.name}'`,
                                fr: `Échec lors du téléchargement du fichier: '${f.name}'`
                            }),
                            { type: 'error' }
                        );
                    });
            });
            if (fileRejections.length > 0) {
                const files = fileRejections.map(f => f.file.name).join(', ');
                toast(
                    to({
                        en: `Invalid files: ${files}`,
                        fr: `Fichiers invalides: ${files}`
                    }),
                    { type: 'error' }
                );
            }
        }
    });
    const extensions = Object.values(accept)
        .reduce((acc, value) => [...acc, ...value], []);

    const removeFromBundles = (fileIds: string[]) => {
        bundles.forEach((b, i) => {
            if (b.fileList.some(f => fileIds.includes(f))) {
                form?.set(
                    getChildFieldName(BUNDLES_KEY, i, 'files'),
                    b.fileList.filter(fid => !filesId.includes(fid))
                );
            }
        });
    };

    const removeUploadedFile = (index: number) => {
        const fileId = files[index]._id;
        removeFromBundles([fileId]);
        removeFile(index);
    };

    const removeUploadedFileById = (id: string) => {
        const index = files.findIndex(f => f._id === id);
        if (index >= 0) {
            removeUploadedFile(index);
        }
    };

    const changeUploadedFile = (index: number, file: UploadedFile) => {
        updateFile(index, file);
    };

    const changeUploadedFileById = (id: string, file: UploadedFile) => {
        const index = files.findIndex(f => f._id === id);
        if (index >= 0) {
            changeUploadedFile(index, file);
        }
    };

    const setBundle = (index: number, bundle: UploadedFileBundle) => {
        form?.set(getChildFieldName(BUNDLES_KEY, index), bundle);
    };

    const setNonBundledFiles = (fileIds: string[]) => {
        removeFromBundles(fileIds);
        replaceFiles([...files].sort((a, b) => {
            const indexA = fileIds.indexOf(a._id);
            const indexB = fileIds.indexOf(b._id);
            if (indexA < 0 || indexB < 0) {
                return 0;
            }
            return indexA - indexB;
        }));
    };

    const addBundle = (fileIds?: string[]) => {
        const bundle = {
            id: uuid(),
            name: `${t('new-bundle')} ${bundleCounter + 1}`,
            fileList: fileIds ?? [],
            include: true,
            amend: false
        } satisfies UploadedFileBundle;
        appendBundles(bundle);
        setBundleCounter(c => c + 1);
    };

    const nonBundledFiles = files.filter(f => bundles.every(b => !b.fileList.includes(f._id)));
    const rootProps = getRootProps();
    return (
        <>
            <div
                className={cn(
                    'tw-p-16 tw-mx-auto tw-rounded-lg',
                    'tw-border-dashed tw-border-2 tw-border-slate-300',
                    'hover:tw-bg-slate-100 hover:tw-border-slate-600',
                    'tw-flex tw-flex-col tw-items-center tw-justify-center',
                    'tw-cursor-pointer tw-text-slate-600 tw-text-center',
                    !!error && '!tw-border-red-600/60 hover:!tw-border-red-600 hover:!tw-bg-red-600/10'
                )}
                {...rootProps}
                ref={(ref) => {
                    form?.ref(filesRootId, ref);
                    rootProps.ref.current = ref;
                }}
            >
                <input className="tw-hidden" {...getInputProps()} />
                <FontAwesomeIcon
                    className="tw-text-5xl tw-text-slate-400 tw-mb-4"
                    icon={faCloudArrowUp}
                />
                {props.children
                    ? props.children
                    : <>
                        <p dangerouslySetInnerHTML={{ __html: to(message) }}>
                        </p>
                        <small>{extensions.join(', ')}</small>
                    </>
                }
            </div>
            {error &&
                <div
                    className={cn(
                        'tw-mt-2 tw-text-destructive tw-text-xs'
                    )}
                >
                    {error}
                </div>
            }
            {files.length > 0 &&
                <div className="tw-flex tw-items-end tw-mt-2">
                    <span>{t('uploaded-files')} ({files.length})</span>
                    {props.bundled &&
                        <Button
                            type="button" variant="outline"
                            className="tw-text-success tw-ml-auto"
                            onClick={() => addBundle()}
                        >
                            <FontAwesomeIcon className="tw-mr-2" icon={faPlus} />
                            {t('new-bundle')}
                        </Button>
                    }
                </div>
            }
            <div
                className={cn(
                    'tw-flex tw-flex-col tw-gap-3',
                    (bundles.length > 0 || nonBundledFiles.length > 0) && 'tw-mt-2'
                )}
            >
                {bundles.map((bundle, index) =>
                    <FileBundle
                        key={bundle.id}
                        files={files}
                        bundle={bundle}
                        index={index}
                        amend={props.amend}
                        group={id}
                        onChangeBundle={(bundle) => setBundle(index, bundle)}
                        onChangeFile={changeUploadedFileById}
                        onRemoveBundle={() => removeBundle(index)}
                        onRemoveFile={removeUploadedFileById}
                    />
                )}
                <ReactSortable
                    group={id}
                    animation={150}
                    className={cn(
                        'tw-flex tw-flex-col tw-gap-1',
                        props.bundled && files.length > 0 && 'tw-min-h-[20px]'
                    )}
                    list={nonBundledFiles.map(f => ({ id: f._id, ...f }))}
                    setList={(list) => setNonBundledFiles(list.map(f => f._id))}
                >
                    {nonBundledFiles.map((file, index) =>
                        <FileItem
                            key={file._id}
                            file={file}
                            standalone
                            amend={props.amend}
                            onChange={(value) => changeUploadedFileById(file._id, value)}
                            onRemove={() => removeUploadedFile(index)}
                        />
                    )}
                </ReactSortable>
            </div>
        </>
    );
}
