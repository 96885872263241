import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
    type IntervalObject,
    type IntervalType,
    WeekSelection
} from '@/components/WeekSelection/WeekSelection';
import { DateTime } from 'luxon';
import { getAppointmentSchedule, getCalendarConfig, getOrgUsers } from '@/composables/api';
import {
    getScheduleIntervalTooltip,
    transformCalendarWeek,
    transformConfig,
    transformUsersConfigSchedule
} from '@/composables/calendar';
import { CalendarAvailabilityType, type CalendarConfig, type ScheduleTaken } from '@/types/api/calendar';
import { type User } from '@/types/api/user';
import { Col, Row } from '@/components/ui/row';
import { ActionBar } from '@/components/ActionBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faFolder } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from '@/composables/translation';
import { useUserStore } from '@/store/user';
import { useForceUpdate } from '@/composables/forceUpdate';
import { Button } from '@/components/ui/button';
import { DatePicker } from '@/components/ui/date-picker';
import { Label } from '@/components/ui/label';
import { Combobox } from '@/components/ui/combobox';
import { type Taken, UpdateTakenDialog } from '@/components/calendar/UpdateTakenDialog';
import { CreateHolidayDialog } from '@/components/calendar/CreateHolidayDialog';
import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';

export function LawyerSchedule() {
    const { t, ct } = useTranslation('schedule');
    const loggedUser = useUserStore(state => state.user);
    const [startDate, setStartDate] = useState<DateTime>(DateTime.now());
    const [takens, setTakens] = useState<ScheduleTaken[]>([]);
    const [schedule, setSchedule] = useState<IntervalObject[][]>([]);
    const [loading, setLoading] = useState(false);
    const [forceUpdate, counter] = useForceUpdate();

    const [config, setConfig] = useState<CalendarConfig>();
    const [users, setUsers] = useState<User[]>([]);
    const [intervalTypes, setIntervalTypes] = useState<IntervalType[]>([]);
    const [usersFilter, setUsersFilter] = useState<User[] | null>([]);
    const [ticketIdFilter, setTicketIdFilter] = useState('');

    const [editOpen, setEditOpen] = useState(false);
    const [editValue, setEditValue] = useState<Taken>();

    const [holidayOpen, setHolidayOpen] = useState(false);

    const weekStartDate = startDate.startOf('week');
    const weekEndDate = weekStartDate.plus({ week: 1 }).minus({ second: 1 });

    function handleClickInterval(interval: IntervalObject) {
        const taken = takens.find(t => t._id.$oid === interval.id);
        if (!taken) {
            return;
        }
        setEditOpen(true);
        setEditValue({
            start_date: interval.interval.start,
            duration: interval.interval.length('minute'),
            interval_user_id: interval.type as string,
            interval_id: interval.id,
            ticket_id: taken.ticket_id ?? '',
            title: taken.title ?? '',
            description: taken.description ?? '',
            location: taken.where ?? ''
        });
    }

    function handleChangeStartDate(value: DateTime | null) {
        const start = (value ?? DateTime.now()).startOf('day');
        const isSameWeek = start.startOf('week').equals(weekStartDate);
        setStartDate(start);
        if (!isSameWeek) {
            setSchedule([]);
        }
    }

    function getIntervalTooltip(t: ScheduleTaken) {
        return getScheduleIntervalTooltip(users, t);
    }

    useEffect(() => {
        Promise.all([
            getOrgUsers({ inactive: true }),
            getCalendarConfig()
        ])
            .then(([users, config]) => {
                const conf = transformConfig(config.data);
                setConfig(conf);
                setUsers(users.data);
                setIntervalTypes(transformUsersConfigSchedule(users.data, conf));
                setUsersFilter([...users.data.filter(u => u.id.$oid === loggedUser?.user_id)]);
            });
    }, []);

    useEffect(() => {
        setLoading(true);
        getAppointmentSchedule({
            start_date: weekStartDate,
            end_date: weekEndDate
        })
            .then((res) => {
                setTakens(res.data.takens);
                setSchedule(
                    transformCalendarWeek(
                        weekStartDate,
                        res.data.takens,
                        t => {
                            const isInternalAppointment = ((t.category ?? 1) > 1) ||
                                (t.type !== CalendarAvailabilityType.AVAILABLE);
                            return ({
                                id: t._id.$oid,
                                ticket_id: t.ticket_id,
                                type: t.users[0]?.id?.$oid,
                                variant: t.service_id,
                                label: t.title,
                                tooltip: getIntervalTooltip(t),
                                ...(isInternalAppointment && {
                                    style: {
                                        background: 'repeating-linear-gradient(' +
                                            '45deg, hsl(var(--color) / 0.3), ' +
                                            'hsl(var(--color) / 0.3) 10px, ' +
                                            'transparent 10px, transparent 20px' +
                                            ')'
                                    }
                                }),
                                zIndex: t.holiday ? -1 : 0
                            });
                        }
                    )
                );
            })
            .finally(() => setLoading(false));
    }, [counter, weekStartDate.toISODate()]);

    return (
        <>
            <ActionBar className="tw-sticky tw-top-[60px]" loading={loading}>
                <Button
                    className="tw-ml-auto"
                    onClick={() => setHolidayOpen(true)}
                >
                    <FontAwesomeIcon className="tw-mr-2" icon={faCalendarPlus} />
                    {t('add-holidays.title')}
                </Button>
            </ActionBar>
            <main className="container-fluid !tw-p-3 md:!tw-p-6 tw-flex tw-flex-col tw-gap-4">
                <Card>
                    <CardHeader>
                        <CardTitle>Filtres</CardTitle>
                    </CardHeader>
                    <CardContent className="tw-flex tw-flex-col tw-gap-y-4">
                        <Row className="tw-items-end">
                            <Col col={4}>
                                <Label htmlFor="date">{ct('date')}</Label>
                                <DatePicker
                                    id="date"
                                    disabled={loading}
                                    value={startDate}
                                    onChange={handleChangeStartDate}
                                />
                            </Col>
                            <Col col={4}>
                                <Label htmlFor="filters.user-filter">
                                    {t('filters.user')}
                                </Label>
                                <Combobox<User, User>
                                    options={users}
                                    getOptionLabel={opt => `${opt.firstname} ${opt.lastname}`}
                                    renderPrependSlot={(u) => {
                                        const ty = intervalTypes.find(t => t.id === u.id.$oid);
                                        return (
                                            <div
                                                className={cn(
                                                    'tw-size-3 tw-rounded-full tw-mr-2',
                                                    'tw-bg-[color:hsl(var(--color))]'
                                                )}
                                                style={ty?.style}
                                            />
                                        );
                                    }}
                                    value={usersFilter}
                                    onChange={setUsersFilter}
                                    multiple
                                />
                            </Col>
                            <Col col={4}>
                                <Label>
                                    {t('filters.ticket-id')}
                                </Label>
                                <Input
                                    prependIcon={
                                        <FontAwesomeIcon
                                            className="tw-text-muted-foreground"
                                            icon={faFolder}
                                        />
                                    }
                                    value={ticketIdFilter}
                                    onChange={(event) => setTicketIdFilter(event.target.value)}
                                />
                            </Col>
                        </Row>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader>
                        <CardTitle>{t('title')}</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <WeekSelection
                            readonly
                            dayStartHour={6}
                            dayEndHour={23}
                            startDate={startDate}
                            intervalMinutes={15}
                            intervalTypes={intervalTypes}
                            intervalFilter={(i) =>
                                (
                                    Number(usersFilter?.length) <= 0 ||
                                    (!!usersFilter?.find(u => u.id.$oid === i.type))
                                ) && (
                                    !ticketIdFilter ||
                                    i.ticket_id?.includes(ticketIdFilter)
                                )
                            }
                            headerClassName="tw-sticky tw-top-[120px]"
                            value={schedule}
                            onClickInterval={handleClickInterval}
                        />
                    </CardContent>
                </Card>
                <UpdateTakenDialog
                    config={config}
                    intervalTypes={intervalTypes}
                    getIntervalTooltip={getIntervalTooltip}
                    value={editValue}
                    open={editOpen}
                    onOpenChange={(value) => {
                        setEditOpen(value);
                        forceUpdate();
                    }}
                />
                <CreateHolidayDialog
                    users={users}
                    open={holidayOpen}
                    onOpenChange={(value) => {
                        setHolidayOpen(value);
                        forceUpdate();
                    }}
                />
            </main>
        </>
    );
}
